input.svelte-y3yqa2 {
  box-sizing: border-box;
  -ms-appearance: none;
  appearance: none;
  border: 2px solid #d9d9d9;
  border-top: 1px solid silver;
  border-radius: .25em;
  width: 100%;
  height: 44px;
  margin-top: 10px;
  padding: 16px 10px;
  font-size: 16px;
}

button.svelte-y3yqa2 {
  text-transform: uppercase;
  letter-spacing: 0;
  background: linear-gradient(95deg, #1c057a, #a04bf9);
  border-color: #0000;
  border-radius: .3em;
  margin-left: auto;
  margin-right: auto;
  padding: .9em 1.8em;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0 0 #0003;
  color: #fff !important;
  line-height: 1.2 !important;
}

form.svelte-y3yqa2 {
  padding: 2em;
}

span.svelte-17kdudh {
  text-align: center;
  color: #000;
  width: 100%;
  padding: 20px 10px 10px;
  font-size: 18px;
  display: block;
}

#loginerror.svelte-17kdudh {
  color: red;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}
/*# sourceMappingURL=_login.0534cc78.css.map */
